import React from "react";
import Link from "next/link";
import AnchorLink from "react-anchor-link-smooth-scroll";

function CmsButton({ component, page_id = "" }) {
  if (component.options.url != "") {
    return (
      <div className={`link`} id={`${component.id ?? ""}`} data-aos="fade-up">
        {component.options.url.startsWith("#") ? (
          <AnchorLink href={component.options.url} className="externalBtn">
            <span>
              {component.options.link_text != ""
                ? component.options.link_text
                : component.options.url}
            </span>
            {component.id == "home-link-2" ? (
              <span className="pl-2">
                <svg
                  width="20"
                  height="8"
                  viewBox="0 0 20 8"
                  className="fill-current"
                >
                  <path d="M19.2188 2.90632L17.0625 0.343819C16.875 0.125069 16.5312 0.0938193 16.2812 0.281319C16.0625 0.468819 16.0312 0.812569 16.2188 1.06257L18.25 3.46882H0.9375C0.625 3.46882 0.375 3.71882 0.375 4.03132C0.375 4.34382 0.625 4.59382 0.9375 4.59382H18.25L16.2188 7.00007C16.0312 7.21882 16.0625 7.56257 16.2812 7.78132C16.375 7.87507 16.5 7.90632 16.625 7.90632C16.7812 7.90632 16.9375 7.84382 17.0312 7.71882L19.1875 5.15632C19.75 4.46882 19.75 3.53132 19.2188 2.90632Z" />
                </svg>
              </span>
            ) : (
              ""
            )}
          </AnchorLink>
        ) : (
          <Link href={component.options.url}>
            <a target="_blank" className="externalBtn">
              <span>
                {component.options.link_text != ""
                  ? component.options.link_text
                  : component.options.url}
              </span>
            </a>
          </Link>
        )}
      </div>
    );
  }
}

export default CmsButton;
